import { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import styles from "./ScrollIndicator.module.scss";

export default function ScrollIndicator({
  scrollContainerRef,
  containerStyles,
}) {
  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current ?? scrollContainerRef;

    if (!scrollContainer) {
      return;
    }

    scrollContainer.addEventListener(
      "scroll",
      debounce(() => {
        setCurrentScrollLeft(scrollContainer.scrollLeft);
      }, 50)
    );

    setMaxScrollWidth(
      scrollContainer?.scrollWidth - scrollContainer?.clientWidth
    );
  }, [scrollContainerRef]);

  return (
    <>
      <div
        className={`${styles.scrollIndicatorContainer} ${
          styles.scrollIndicatorLeft
        } ${currentScrollLeft < 5 ? styles.hide : ""}`}
        style={containerStyles}
      >
        <div className={`${styles.scrollIndicator}`} />
      </div>
      <div
        className={`${styles.scrollIndicatorContainer} ${
          styles.scrollIndicatorRight
        } ${maxScrollWidth - 10 <= currentScrollLeft ? styles.hide : ""}`}
        style={containerStyles}
      >
        <div className={`${styles.scrollIndicator}`} />
      </div>
    </>
  );
}

import React, { useRef, useState, useEffect } from "react";
import dynamic from "next/dynamic";
const SideScrollButtons = dynamic(
  () => import("components/ui/buttons/SideScrollButtons/SideScrollButtons")
);

import Link from "next/link";
import Image from "next/image";
const SectionTitle = dynamic(
  () => import("components/typography/SectionTitle/SectionTitle")
);

import styles from "./CardRow.module.scss";
import { site } from "/config/index";

import ScrollIndicator from "../ui/ScrollIndicator/ScrollIndicator";

// const SideScrollButtons = React.forwardRef((props, ref) => {

const CardRow = React.forwardRef((props, ref) => {
  const {
    cards,
    sectionTitle,
    headingType = "h2",
    titleLink,
    sectionSubtitle,
    sectionSlug,
    id,
    seeAll = true,
    justify = "spaceBetween",
    children,
    sideScroll = "none",
    sideScrollButtons = true,
    scrollAfterCardsCount = 3,
    titleStyles,
    scrollIndicator = true,
  } = props;

  const [scrollContainer, setScrollContainer] = useState(null);

  const HeadingTag = (props) => {
    return React.createElement(
      headingType,
      { className: props.className, style: props.style },
      props.children
    );
  };

  const cardsRef = useRef();

  useEffect(() => {
    let scrollContainer = cardsRef.current;

    if (!cardsRef.current.classList.contains("wn-scroll-container")) {
      scrollContainer = cardsRef.current.querySelector(".wn-scroll-container");
    }

    if (!scrollContainer) {
      return;
    }

    setScrollContainer(scrollContainer);
  }, [cardsRef]);

  if (!cards) {
    return null;
  }

  const scrollButtonsRef = !!ref?.current ? ref : cardsRef;

  // const sideScrollButtonsElement = (
  //   <div className={`${styles.sideScrollButtons}`}>
  //     {sideScrollButtons === true &&
  //       cards.length > scrollAfterCardsCount &&
  //       sideScroll !== "none" && (
  //         <SideScrollButtons cardRowRef={scrollButtonsRef} />
  //       )}
  //   </div>
  // );

  return (
    <div
      className={`${styles.cardRow} ${
        scrollIndicator && sideScroll !== "none"
          ? styles.hasScrollIndicator
          : ""
      }`}
      id={id ?? null}
    >
      {sectionTitle && (
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText}`}>
            {titleLink && (
              <Link href={titleLink}>
                <HeadingTag
                  style={titleStyles}
                  className={`sectionTitle ${styles.title}`}
                >
                  <SectionTitle title={sectionTitle} />
                </HeadingTag>
              </Link>
            )}
            {!titleLink && (
              <HeadingTag className={`${styles.title}`} style={titleStyles}>
                <SectionTitle title={sectionTitle} subtitle={sectionSubtitle} />
              </HeadingTag>
            )}
          </div>
          {/* {sideScrollButtonsElement} */}
          {seeAll && (
            <Link
              href={`${site}${sectionSlug}`}
              aria-label={`see all ${sectionTitle}`}
              className={`${styles.sectionLink}`}
            >
              See all
              <span className={`${styles.linkArrowWrapper}`}>
                <Image
                  src="/assets/img/icon/awrro-black.svg"
                  alt="Arrow black icon"
                  width="30"
                  height="14"
                />
              </span>
            </Link>
          )}
        </div>
      )}

      {seeAll && !sectionTitle && (
        <>
          {/* <div className={`${styles.sideScrollButtons}`}>
            {sideScrollButtons === true &&
              cards.length > scrollAfterCardsCount &&
              sideScroll !== "none" && (
                <SideScrollButtons cardRowRef={scrollButtonsRef} />
              )}
          </div> */}
          <Link
            href={`${site}${sectionSlug}`}
            aria-label={`see all ${sectionTitle}`}
            className={`${styles.sectionLink} ${styles.seeAll} ${styles.noTitle}`}
          >
            <span>See all</span>
            <span className={`${styles.linkArrowWrapper}`}>
              <Image
                src="/assets/img/icon/awrro-black.svg"
                alt="Arrow black icon"
                width="30"
                height="14"
              />
            </span>
          </Link>
        </>
      )}

      {/* {!seeAll && !sectionTitle && sideScrollButtons && (
        <div className={`${styles.sideScrollButtons} ${styles.standalone}`}>
          {sideScrollButtons === true &&
            cards.length > scrollAfterCardsCount &&
            sideScroll !== "none" && (
              <SideScrollButtons cardRowRef={scrollButtonsRef} />
            )}
        </div>
      )} */}

      {children}

      <div
        className={`${styles.cards}
        ${styles[`justify-${justify}`]}
        wn-scroll-container
        ${
          sideScroll && sideScroll === "mobile" ? styles.mobileSideScroll : ""
        } ${
          sideScroll && sideScroll === "desktop" ? styles.desktopSideScroll : ""
        } ${sideScroll && sideScroll === "all" ? styles.allSideScroll : ""}`}
        ref={cardsRef}
      >
        {cards}
      </div>

      {sideScrollButtons === true &&
        cards.length > scrollAfterCardsCount &&
        sideScroll !== "none" && (
          <div className={`${styles.sideScrollButtons}`}>
            <SideScrollButtons cardRowRef={scrollButtonsRef} type="sides" />
          </div>
        )}

      {scrollIndicator && sideScroll !== "none" && (
        <ScrollIndicator scrollContainerRef={cardsRef} />
      )}
    </div>
  );
});

CardRow.displayName = "CardRow";
export default CardRow;
